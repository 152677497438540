import React, { Component } from 'react';
import { Controller, Scene } from 'react-scrollmagic';
// eslint-disable-next-line
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import { Tween } from 'react-gsap';
import IsScrolling from '../libs/react-is-scrolling';
import ReactGA from "react-ga";

import './Outro.scss';

import Fireworks from '../Fireworks/Fireworks';

class Outro extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allowScrollExit: false,
      allowScroll: true,
      showElements: false,
    };

    this.getScrollDirection = this.getScrollDirection.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.backToTop = this.backToTop.bind(this);
  }

  backToTop() {
    ReactGA.event({
      category: 'Feature',
      action: "Back to top button used",
    });

    this.props.setStep('isQuizComplete', false);
    this.props.parentSetState({
      buttonsBottomShow: true,
      error: {
        ...this.props.parentState.error,
        showError: false,
      },
    })

    scroller.scrollTo('question-0', {
      containerId: 'root',
      duration: 10000,
      smooth: this.props.compatibilityMode ? false : true,
      ignoreCancelEvents: true,
    });

    if (this.props.compatibilityMode) {
      this.props.parentSetState({
        currentQuestion: 0,
        compatBackToTop: true,
      });

      setTimeout(() => {
        this.props.snapEmojiToAnswer(this.props.parentState.answers[`q${this.props.parentState.currentQuestion}`]);
      }, 250);
    }
  }

  getScrollDirection() {
    let direction = null;

    if (this.props.isScrollingDown) {
      direction = 'down';
    } else if (this.props.isScrollingUp) {
      direction = 'up';
    }

    return direction;
  }

  handleScroll(progress) {
    if (this.props.parentState.answers[`q${this.props.totalQuestions - 1}`] === null) {
      return;
    }

    if (this.props.compatibilityMode) {
      return;
    }

    const direction = this.getScrollDirection();

    if (progress > 0.3 && this.state.allowScroll && direction === 'down') {
      this.setState({
        allowScroll: false,
        showElements: true,
        allowScrollExit: true,
      });

      this.props.setStep('isQuizComplete', true);

      this.props.parentSetState({
        buttonsBottomShow: false,
        isTextDownArrowVisible: false,
        fixStem: false,
        fixStemTop: true,
      });

      scroller.scrollTo('outro', {
        containerId: 'root',
        duration: 1000,
        smooth: this.props.compatibilityMode ? false : true,
        ignoreCancelEvents: true,
      });
    }

    if (progress < 0.3 && this.state.allowScrollExit && direction === 'up') {
      this.props.parentSetState({
        fixStem: true,
        fixStemTop: false,
      })

      this.setState({
        allowScrollExit: false,
        allowScroll: true,
        showElements: false,
      });
    }
  }

  render() {
    const CLASS_NAME = this.props.parentState.answers[`q${this.props.totalQuestions - 1}`] !== null ? 'outro' : 'outro hide';
    const CLASS_SUBMIT_ANSWERS = this.state.showElements || this.props.parentState.compatShowOutroElements ? 'showMyMatches button visible' : 'invisible';
    const CLASS_CHANGE_ANSWERS = this.state.showElements || this.props.parentState.compatShowOutroElements ? 'secondaryButton visible' : 'invisible';
    const CLASS_MESSAGE = this.state.showElements || this.props.parentState.compatShowOutroElements ? 'visible' : 'invisible';

    const TEXT_BUTTON = this.props.parentState.resultsPending ? 'Getting matches...' : 'Show my matches';

    const NAME = this.props.parentState.springpod.user_foreName ?
      this.props.parentState.springpod.user_foreName :
      'Well done!';

    const TOP_SUBMIT_ANSWERS = (window.innerHeight / 2) + (this.props.parentState.settings.EMOJI_HEIGHT / 2) + 20;

    return (
      <section className={CLASS_NAME} name="outro">
        <div id="outroTrigger" />

        <div className="top">
          <div className={CLASS_MESSAGE}>
            <p className="bigText name">{NAME}</p>
            <p className="message">Are you ready to see your personality types?</p>
          </div>
        </div>
        <button className={CLASS_SUBMIT_ANSWERS} style={{top: TOP_SUBMIT_ANSWERS}} onClick={() => this.props.sendAnswers()}>{TEXT_BUTTON}</button>
        <button className={CLASS_CHANGE_ANSWERS} onClick={this.backToTop}>I want to change an answer</button>

        <Controller container="#root">
          <Scene
            triggerElement="#outroTrigger"
            duration={window.innerHeight / 2}
            pin={false}>
            {(progress) => {
              this.handleScroll(progress);

              return (
                <Tween
                  totalProgress={progress}
                  yoyo={true}
                  repeat={1}
                  paused />
              );
            }}
          </Scene>
        </Controller>

        <Fireworks showElements={this.state.showElements} />
      </section>
    );
  }
}

export default IsScrolling(Outro);
