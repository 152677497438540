import React, { Component } from 'react';

import './EmojiSelector.scss';

import type0 from './type-0.svg';
import type1 from './type-1.svg';
import type2 from './type-2.svg';
import type3 from './type-3.svg';
import type4 from './type-4.svg';
import type5 from './type-5.svg';
import type6 from './type-6.svg';
import type7 from './type-7.svg';

import hair0 from './hair-0.svg';
import hair1 from './hair-1.svg';
import hair2 from './hair-2.svg';
import hair3 from './hair-3.svg';
import hair4 from './hair-4.svg';

import skin0 from './skin-0.svg';
import skin1 from './skin-1.svg';
import skin2 from './skin-2.svg';
import skin3 from './skin-3.svg';
import skin4 from './skin-4.svg';

const emojiTypes = [
  {
    img: type0,
  },
  {
    img: type1,
  },
  {
    img: type2,
  },
  {
    img: type3,
  },
  {
    img: type4,
  },
  {
    img: type5,
  },
  {
    img: type6,
  },
  {
    img: type7,
  },
];

const hairStyles = [
  {
    img: hair0,
  },
  {
    img: hair1,
  },
  {
    img: hair2,
  },
  {
    img: hair3,
  },
  {
    img: hair4,
  },
];

const skinTypes = [
  {
    img: skin0,
  },
  {
    img: skin1,
  },
  {
    img: skin2,
  },
  {
    img: skin3,
  },
  {
    img: skin4,
  },
];

class EmojiSelector extends Component {
  render() {
    const typeButtons = emojiTypes.map((type, index) => {
      const className = this.props.emoji.type === index ? 'item noRound selected' : 'item noRound';
      return <button key={index} className={className} onClick={() => this.props.setEmoji('type', index)}><img src={type.img} alt="" /></button>;
    });

    const hairButtons = hairStyles.map((h, index) => {
      const className = this.props.emoji.hair === index ? 'item selected' : 'item';
      return <button key={index} className={className} onClick={() => this.props.setEmoji('hair', index)}><img src={h.img} alt="" /></button>;
    });

    const skinButtons = skinTypes.map((h, index) => {
      const className = this.props.emoji.skin === index ? 'item selected' : 'item';
      return <button key={index} className={className} onClick={() => this.props.setEmoji('skin', index)}><img src={h.img} alt="" /></button>;
    });

    return (
      <>
        <div className="emojiSelectorBg">
          <div className="emojiPosition" ref="emojiPosition" />
        </div>
        <div className="emojiSelector">
          <div className="row">
            <p>Choose an emoji</p>
            <div className="items">{typeButtons}</div>
          </div>
          <div className="row">
            <p>Hair colour</p>
            <div className="items circular">{hairButtons}</div>
          </div>
          <div className="row">
            <p>Skin colour</p>
            <div className="items circular">{skinButtons}</div>
          </div>
        </div>
      </>
    );
  }
}

export default EmojiSelector;
