import _ from "lodash";
import React, { Component } from "react";
import disableScroll from "disable-scroll";
// eslint-disable-next-line
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller
} from "react-scroll";
import Draggable from "react-draggable";
import Bowser from "bowser";
import queryString from "query-string";
import axios from "axios";
import ReactGA from "react-ga";
import queue from "queue";

import "./Quiz.scss";
import "./emoji.scss";
import "./dot.scss";

import Intro from "../Intro/Intro";
import Icebreaker from "../Icebreaker/Icebreaker";
import Question from "../Question/Question";
import Progress from "../Progress/Progress";
import Outro from "../Outro/Outro";

import questions from "../Questions.js";
import calculateResults from "../calculateResults";

import iconArrowDown from "./icons/icon_arrow_down.svg";
import iconArrowUp from "./icons/icon_arrow_up.svg";

const SETTINGS = {
  xs_mobile: {
    EMOJI_WIDTH: 70,
    EMOJI_HEIGHT: 70,
    DOT_WIDTH: 340,
    DOT_HEIGHT: 340,
    ICEBREAKER_BUBBLE_SIZE: 350,
    BUTTON_HEIGHT: 43,
    DISTANCE_FROM_SLIDER: 100
  },
  mobile: {
    EMOJI_WIDTH: 100,
    EMOJI_HEIGHT: 100,
    DOT_WIDTH: 500,
    DOT_HEIGHT: 500,
    ICEBREAKER_BUBBLE_SIZE: 450,
    BUTTON_HEIGHT: 46,
    DISTANCE_FROM_SLIDER: 130
  },
  tablet: {
    EMOJI_WIDTH: 100,
    EMOJI_HEIGHT: 100,
    DOT_WIDTH: 523,
    DOT_HEIGHT: 523,
    ICEBREAKER_BUBBLE_SIZE: 500,
    BUTTON_HEIGHT: 46,
    DISTANCE_FROM_SLIDER: 130
  }
};

class Quiz extends Component {
  constructor(props) {
    super(props);

    // Get browser type
    const browser = Bowser.getParser(window.navigator.userAgent);

    // Initialise state
    this.state = {
      viewport: "xs_mobile",
      settings: SETTINGS["xs_mobile"],
      currentQuestion: null,
      answers: {},
      emoji: {
        type: 0,
        skin: 0,
        hair: 0
      },
      emojiPosition: {
        x: 0,
        y: 0
      },
      steps: {
        isEmojiSelectionComplete: false,
        isIcebreakerStarted: false,
        isIcebreakerComplete: false,
        isQuizStarted: false,
        isQuizComplete: false
      },
      emojiDraggableDisabled: true,
      isTextDownArrowVisible: true,
      textDownArrow: "Next",
      fixStem: false,
      fixStemTop: false,
      returnToQuestion: false,
      buttonsBottomShow: true,
      buttonsBottomFade: false,
      isFreeScrolling: false,
      isEmojiSelected: false,
      browserEngine: browser.getEngine().name,
      error: {
        showError: false,
        errorCode: null
      },
      springpod: {
        user_foreName: null,
        url_fetch_personTypes: null,
        url_post_result: null,
        url_display_results: null,
        base_url_api: null,
        base_url_web: null
      },
      disableConfirmClose: false,
      resultsPending: false,
      compatShowOutroElements: false,
      compatBackToTop: false
    };

    // Initialise a placeholder for each answer
    questions.map((q, i) => {
      // eslint-disable-next-line
      return (this.state.answers = {
        ...this.state.answers,
        [`q${i}`]: null
      });
    });

    // Bind `this`
    this.disableScroll = this.disableScroll.bind(this);
    this.parentSetState = this.parentSetState.bind(this);
    this.setStep = this.setStep.bind(this);
    this.setEmoji = this.setEmoji.bind(this);
    this.setEmojiPosition = this.setEmojiPosition.bind(this);
    this.resetEmojiPosition = this.resetEmojiPosition.bind(this);
    this.handleEmojiDragStart = this.handleEmojiDragStart.bind(this);
    this.handleEmojiDrag = this.handleEmojiDrag.bind(this);
    this.handleEmojiDragStop = this.handleEmojiDragStop.bind(this);
    this.setCurrentQuestion = this.setCurrentQuestion.bind(this);
    this.snapEmojiToAnswer = this.snapEmojiToAnswer.bind(this);
    this.clickAnswer = this.clickAnswer.bind(this);
    this.renderUpArrow = this.renderUpArrow.bind(this);
    this.moveUp = this.moveUp.bind(this);
    this.renderDownArrow = this.renderDownArrow.bind(this);
    this.moveDown = this.moveDown.bind(this);
    this.renderQuestionBubbles = this.renderQuestionBubbles.bind(this);
    this.sendAnswers = this.sendAnswers.bind(this);
    this.recalculate = this.recalculate.bind(this);

    // Compatibility mode for IE
    this.compatibilityMode = false;
    if (this.state.browserEngine === "Trident") {
      this.compatibilityMode = true;
    }

    // Analytics trackers
    this.timeStart = null;
    this.isEmojiUnchanged = true;
  }

  componentDidMount() {
    // Initially lock scroll for emoji editor
    this.disableScroll(true);

    let _this = this;

    // Callbacks scrollEvents
    Events.scrollEvent.register("end", (to, element) => {
      if (_this.state.compatibilityMode) {
        return;
      }

      if (to && to === "icebreaker" && !_this.state.steps.isIcebreakerStarted) {
        this.setStep("isIcebreakerStarted", true);
        this.disableScroll(true);
      }

      if (to && to.includes("question-") && _this.state.returnToQuestion) {
        _this.setState({ returnToQuestion: false });
        _this.snapEmojiToAnswer(
          _this.state.answers[`q${_this.state.currentQuestion}`]
        );
        _this.disableScroll(false);
      }

      if (to && to.includes("question-") && !_this.state.returnToQuestion) {
        if (!_this.state.isFreeScrolling) {
          _this.disableScroll(true);
        }
      }

      if (to === "outro") {
        _this.disableScroll(true);
      }
    });

    // Set emoji position
    const emojiPosition = document
      .querySelector(".emojiPosition")
      .getBoundingClientRect();
    this.setEmojiPosition(emojiPosition.left, emojiPosition.top + 5);

    // Resize window
    window.addEventListener("resize", this.recalculate);
    this.recalculate();

    // Confirm before closing quiz
    window.onbeforeunload = e => {
      if (process.env.NODE_ENV === "development") {
        return;
      }

      if (
        !this.state.steps.isEmojiSelectionComplete ||
        this.state.disableConfirmClose
      ) {
        return;
      }

      const dialogText =
        "You're about to close the quiz and lose your progress.";
      e.returnValue = dialogText;
      return dialogText;
    };

    // Populate Springpod data from URL query params
    const QUERY_PARAMS = queryString.parse(this.props.location.search);

    this.setState({
      springpod: {
        user_foreName: QUERY_PARAMS.user_foreName || null,
        url_fetch_personTypes: QUERY_PARAMS.url_fetch_personTypes || null,
        url_post_result: QUERY_PARAMS.url_post_result || null,
        url_display_results: QUERY_PARAMS.url_display_results || null,
        base_url_api: QUERY_PARAMS.base_url_api || null,
        base_url_web: QUERY_PARAMS.base_url_web || null
      }
    });

    // Start timer
    this.timeStart = new Date();

    // Send quiz opened analytics event
    ReactGA.event({
      category: 'Quiz',
      action: "Opened quiz",
    });
  }

  recalculate() {
    let VIEWPORT = null;

    // Fix iOS Safari treating 100vh differently to Chrome
    // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    const height = window.innerHeight;
    const vh = height * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    // Calculate viewport size
    if (window.innerWidth < 375) {
      VIEWPORT = "xs_mobile";
    } else if (window.innerWidth < 768) {
      VIEWPORT = "mobile";
    } else {
      VIEWPORT = "tablet";
    }

    this.setState({
      viewport: VIEWPORT,
      settings: SETTINGS[VIEWPORT]
    });

    if (!this.state.steps.isEmojiSelectionComplete) {
      const emojiPosition = document
        .querySelector(".emojiPosition")
        .getBoundingClientRect();
      this.setEmojiPosition(emojiPosition.left, emojiPosition.top + 5);
    }

    if (
      this.state.steps.isEmojiSelectionComplete &&
      !this.state.steps.isIcebreakerStarted
    ) {
      this.setEmojiPosition(
        window.innerWidth / 2 - this.state.settings.EMOJI_WIDTH / 2,
        window.innerHeight / 2 - this.state.settings.EMOJI_HEIGHT / 2
      );
    }

    if (this.state.steps.isQuizStarted && !this.state.steps.isQuizComplete) {
      if (this.state.answers[`q${this.state.currentQuestion}`] === null) {
        this.setState({
          emojiPosition: {
            x: window.innerWidth / 2 - this.state.settings.EMOJI_WIDTH / 2,
            y: window.innerHeight / 2 - this.state.settings.DISTANCE_FROM_SLIDER
          }
        });
      } else {
        this.snapEmojiToAnswer(
          this.state.answers[`q${this.state.currentQuestion}`]
        );
      }

      // Gracefully character back to last question if window
      // is resized between questions
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = setTimeout(() => {
        scroller.scrollTo(`question-${this.state.currentQuestion}`, {
          containerId: "root",
          duration: 1000,
          smooth: true,
          ignoreCancelEvents: true
        });
      }, 500);
    }

    if (this.state.steps.isQuizStarted && this.state.steps.isQuizComplete) {
      this.setEmojiPosition(
        window.innerWidth / 2 - this.state.settings.EMOJI_WIDTH / 2,
        window.innerHeight / 2 - this.state.settings.EMOJI_HEIGHT / 2
      );
    }
  }

  disableScroll(disable) {
    const element = document.getElementsByTagName("BODY")[0];

    if (disable) {
      disableScroll.on(document.querySelector("#root"), {
        disableWheel: true
      });
      element.classList.add("disableScroll");
    } else {
      if (this.compatibilityMode) {
        return;
      }

      disableScroll.off();
      element.classList.remove("disableScroll");
    }
  }

  // MARK: Helper function for setting parent state from child components
  parentSetState(newState) {
    this.setState(newState);
  }

  // MARK: Set step flag in parent state container
  setStep(key, value) {
    this.setState({
      steps: {
        ...this.state.steps,
        [key]: value
      }
    });
  }

  // EMOJI
  setEmoji(feature, id) {
    if (this.isEmojiUnchanged) {
      ReactGA.event({
        category: 'Feature',
        action: "Emoji changed",
      });

      this.isEmojiUnchanged = false;
    }

    this.setState({
      emoji: {
        ...this.state.emoji,
        [feature]: id
      }
    });
  }

  setEmojiPosition(x, y) {
    if (!x && !y) {
      return;
    }

    this.setState({
      emojiPosition: {
        x: x,
        y: y
      }
    });
  }

  resetEmojiPosition() {
    this.setState({
      emojiPosition: {
        x: window.innerWidth / 2 - this.state.settings.EMOJI_WIDTH / 2,
        y: window.innerHeight / 2 - this.state.settings.EMOJI_HEIGHT / 2
      }
    });
  }

  handleEmojiDragStart() {
    this.disableScroll(true);
  }

  handleEmojiDrag(e, position) {
    const { x, y } = position;
    this.setState({ emojiPosition: { x, y } });
  }

  handleEmojiDragStop(e, position) {
    const EMOJI_AREA = document
      .querySelector(".react-draggable")
      .getBoundingClientRect();
    const TARGET_AREAS = document.querySelectorAll(
      `.question-${this.state.currentQuestion} .answer`
    );
    let selectedAnswer = null;

    TARGET_AREAS.forEach((area, index) => {
      const TARGET_AREA = area.getBoundingClientRect();

      let overlap = !(
        EMOJI_AREA.right - 50 < TARGET_AREA.left ||
        EMOJI_AREA.left + 50 > TARGET_AREA.right ||
        EMOJI_AREA.bottom - 50 < TARGET_AREA.top ||
        EMOJI_AREA.top + 50 > TARGET_AREA.bottom
      );

      if (overlap) {
        selectedAnswer = index;
      }
    });

    if (selectedAnswer === null) {
      this.setState({
        emojiPosition: {
          x: window.innerWidth / 2 - this.state.settings.EMOJI_WIDTH / 2,
          y: window.innerHeight / 2 - this.state.settings.DISTANCE_FROM_SLIDER
        },
        answers: {
          ...this.state.answers,
          [`q${this.state.currentQuestion}`]: selectedAnswer
        },
        isTextDownArrowVisible: false,
        buttonsBottomFade: true,
        isFreeScrolling: false,
        isEmojiSelected: false
      });

      this.disableScroll(true);
    } else {
      this.snapEmojiToAnswer(selectedAnswer);
    }
  }

  // ANSWERING
  setCurrentQuestion(questionNum) {
    if (this.state.steps.isIcebreakerComplete) {
      this.setState({ currentQuestion: questionNum });
    }
  }

  snapEmojiToAnswer(selectedAnswer) {
    const TARGET_AREAS = document.querySelectorAll(
      `.question-${this.state.currentQuestion} .answer .icon`
    );
    const BBOX = TARGET_AREAS[selectedAnswer].getBoundingClientRect();
    const TEXT_DOWN_ARROW =
      this.state.currentQuestion === questions.length - 1
        ? "Scroll down..."
        : "Next question";

    this.setState({
      emojiPosition: {
        x: BBOX.left + BBOX.width / 2 - this.state.settings.EMOJI_WIDTH / 2,
        y: BBOX.top + BBOX.height / 2 - this.state.settings.EMOJI_HEIGHT / 2
      },
      answers: {
        ...this.state.answers,
        [`q${this.state.currentQuestion}`]: selectedAnswer
      },
      isTextDownArrowVisible: true,
      textDownArrow: TEXT_DOWN_ARROW,
      buttonsBottomFade: false,
      isEmojiSelected: true,
      isFreeScrolling: true
    });

    this.disableScroll(false);
  }

  clickAnswer(selectedAnswer) {
    this.snapEmojiToAnswer(selectedAnswer);
  }

  // NAVIGATION BUTTONS
  renderUpArrow() {
    let className = "navArrow navArrowUp";
    if (this.state.currentQuestion > 0) {
      className += " visible";
    } else {
      className += " invisible";
    }

    return (
      <div className={className} onClick={this.moveUp}>
        <img src={iconArrowUp} alt="" />
      </div>
    );
  }

  moveUp() {
    if (
      this.state.currentQuestion === null ||
      this.state.currentQuestion === 0
    ) {
      return false;
    }

    let question = this.state.currentQuestion - 1;

    if (this.state.steps.isQuizComplete) {
      question = this.state.currentQuestion;
      this.setStep("isQuizComplete", false);
      this.setState({
        error: {
          ...this.state.error,
          showError: false
        },
        buttonsBottomShow: true
      });
    }

    scroller.scrollTo(`question-${question}`, {
      containerId: "root",
      duration: 1000,
      smooth: this.compatibilityMode ? false : true,
      ignoreCancelEvents: true
    });

    if (this.compatibilityMode) {
      this.setState({
        currentQuestion: this.state.currentQuestion - 1
      });

      setTimeout(() => {
        this.snapEmojiToAnswer(
          this.state.answers[`q${this.state.currentQuestion}`]
        );
      }, 250);
    }
  }

  renderDownArrow() {
    let className = "navArrow navArrowDown ";

    if (this.state.buttonsBottomShow) {
      className += "visible";
    } else {
      className += "invisible";
    }

    const CLASS_IMAGE = this.state.buttonsBottomFade ? "fade" : "";

    return (
      <div className={className} onClick={this.moveDown}>
        <img className={CLASS_IMAGE} src={iconArrowDown} alt="" />
      </div>
    );
  }

  moveDown() {
    // Mark emoji selection as complete
    if (!this.state.steps.isEmojiSelectionComplete) {
      this.setState({
        steps: {
          ...this.state.steps,
          isEmojiSelectionComplete: true
        },
        textDownArrow: this.compatibilityMode ? "Next" : "Scroll down..."
      });

      this.setEmojiPosition(
        window.innerWidth / 2 - this.state.settings.EMOJI_WIDTH / 2,
        window.innerHeight / 2 - this.state.settings.EMOJI_HEIGHT / 2
      );

      this.disableScroll(false);

      // Remove emoji selector container so it can't be edited with opacity 0
      setTimeout(() => {
        const element = document.getElementById("emojiSelectorContainer");
        element.parentNode.removeChild(element);
      }, 250);

      return;
    }

    // Use the move down button to move to icebreaker
    if (
      this.state.steps.isEmojiSelectionComplete &&
      !this.state.steps.isIcebreakerStarted
    ) {
      scroller.scrollTo("icebreaker", {
        containerId: "root",
        duration: 1000,
        smooth: this.compatibilityMode ? false : true,
        ignoreCancelEvents: true
      });

      return;
    }

    // Use the move down button to move to next question
    if (
      this.state.steps.isEmojiSelectionComplete &&
      this.state.steps.isIcebreakerStarted
    ) {
      // Go from icebreaker to first question
      let question = 0;

      if (this.state.currentQuestion !== null) {
        question = this.state.currentQuestion + 1;
      }

      // Ignore skip to next if current question is unanswered
      if (this.state.answers[`q${this.state.currentQuestion}`] === null) {
        return false;
      }

      scroller.scrollTo(`question-${question}`, {
        containerId: "root",
        duration: 1000,
        smooth: this.compatibilityMode ? false : true,
        ignoreCancelEvents: true
      });

      if (this.compatibilityMode) {
        let currentQuestion = 0;

        if (this.state.currentQuestion !== null) {
          currentQuestion = this.state.currentQuestion + 1;
        }

        this.setState({
          currentQuestion: currentQuestion,
          emojiPosition: {
            x: window.innerWidth / 2 - this.state.settings.EMOJI_WIDTH / 2,
            y: window.innerHeight / 2 - this.state.settings.DISTANCE_FROM_SLIDER
          },
          isEmojiSelected: false
        });

        setTimeout(() => {
          if (
            this.state.answers[`q${this.state.currentQuestion}`] !== null &&
            this.state.compatBackToTop
          ) {
            this.snapEmojiToAnswer(
              this.state.answers[`q${this.state.currentQuestion}`]
            );
          }
        }, 250);
      }
    }

    if (this.state.currentQuestion === questions.length - 1) {
      scroller.scrollTo("outro", {
        containerId: "root",
        duration: 1000,
        smooth: this.compatibilityMode ? false : true,
        ignoreCancelEvents: true
      });

      if (this.compatibilityMode) {
        this.resetEmojiPosition();

        this.setState({
          buttonsBottomShow: false,
          isTextDownArrowVisible: false,
          fixStem: false,
          fixStemTop: true,
          compatShowOutroElements: true,
          steps: {
            ...this.state.steps,
            isQuizComplete: true
          }
        });
      }
    }
  }

  renderQuestionBubbles() {
    return questions.map((question, index) => {
      return (
        <Question
          key={index}
          index={index}
          compatibilityMode={this.compatibilityMode}
          parentState={this.state}
          parentSetState={this.parentSetState}
          question={question}
          totalQuestions={questions.length}
          setStep={this.setStep}
          setEmojiPosition={this.setEmojiPosition}
          resetEmojiPosition={this.resetEmojiPosition}
          clickAnswer={this.clickAnswer}
          disableScroll={this.disableScroll}
          setCurrentQuestion={this.setCurrentQuestion}
        />
      );
    });
  }

  getTimeToComplete = (diff) => {
    if (diff < 300) {
      return 'less than 5 minutes';
    } else if (diff >= 300 && diff < 600) {
      return 'between 5 and 10 minutes';
    } else if (diff >= 600 && diff < 900) {
      return 'between 10 and 15 minutes';
    } else if (diff >= 900 && diff < 1200) {
      return 'between 15 and 20 minutes';
    } else {
      return 'more than 20 minutes';
    }
  }

  sendAnswers() {
    if (this.state.resultsPending) {
      return;
    }

    this.setState({
      disableConfirmClose: true,
      resultsPending: true
    });

    // Stop timer
    const timeEnd = new Date();
    const timeDiff = (timeEnd.getTime() - this.timeStart.getTime()) / 1000;

    ReactGA.event({
      category: 'Quiz',
      action: "Completed quiz",
      label: this.getTimeToComplete(timeDiff),
    });

    const entries = Object.entries(this.state.answers);

    const analyticsQueue = new queue({ concurrency: 1 });

    for (const [question, answer] of entries) {
      analyticsQueue.push((next) => {
        setTimeout(() => {
          const questionId = parseInt(question.replace("q", ""), 10);
          const questionString = `Q${questionId + 1} ${questions[questionId].question}`;
          const answerString = `A${answer + 1} ${
            questions[questionId].answers[answer].answer
          }`;

          try {
            ReactGA.event({
              category: 'Answer',
              action: questionString,
              label: answerString,
            });
          } catch (e) {
            // do nothing
          }

          next();
        }, 200);
      });
    }

    analyticsQueue.start((err) => {
      const RESULTS = calculateResults(this.state.answers);
      const RESULTS_ARR = [];

      for (const key in RESULTS) {
        if (!RESULTS.hasOwnProperty(key)) {
          continue;
        }

        RESULTS_ARR.push({
          type: key,
          count: RESULTS[key]
        });
      }

      const SORTED_RESULTS_ARR = _.reverse(_.sortBy(RESULTS_ARR, o => o.count));
      const MATCHED = _.slice(SORTED_RESULTS_ARR, 0, 3).map(o => {
        return { id: o.type };
      });
      const NOT_MATCHED = _.slice(SORTED_RESULTS_ARR, 3, 12).map(o => {
        return { id: o.type };
      });

      const URL_SPRINGPOD_POST = `${this.state.springpod.base_url_api}/${
        this.state.springpod.url_post_result
      }`;
      const URL_SPRINGPOD_REDIRECT = `${this.state.springpod.base_url_web}${
        this.state.springpod.url_display_results
      }`;
      const SPRINGPOD_PAYLOAD = {
        data: [
          {
            personTypes: MATCHED,
            personTypesLessPreferred: NOT_MATCHED,
            strongHandStartTime: "2019-01-04T00:00:00.000Z",
            strongHandEndTime: "2019-01-04T00:00:03.000Z",
            weakHandStartTime: "2019-01-04T00:00:04.000Z",
            weakHandEndTime: "2019-01-04T00:00:07.000Z"
          }
        ]
      };

      if (process.env.REACT_APP_DISABLE_REDIRECT !== 'true') {
        axios
          .post(URL_SPRINGPOD_POST, SPRINGPOD_PAYLOAD, { withCredentials: true })
          .then(res => (window.location.href = URL_SPRINGPOD_REDIRECT))
          .catch(err => {
            this.setState({
              disableConfirmClose: false,
              resultsPending: false,
              error: {
                ...this.state.error,
                showError: true,
                errorCode:
                  err.response && err.response.status ? err.response.status : null
              }
            });
          });
      }
    });
  }

  render() {
    // Change emoji size if answer is selected
    const CLASS_EMOJI = this.state.isEmojiSelected
      ? "emoji selected"
      : "emoji unselected";
    const CLASS_DRAGGABLE = this.state.steps.isEmojiSelectionComplete
      ? ""
      : "editing";
    const CLASS_TEXT_DOWN_ARROW = this.state.isTextDownArrowVisible
      ? "textDownArrow visible"
      : "textDownArrow invisible";
    const CLASS_ERROR = this.state.error.showError ? "error visible" : "hide";

    let CLASS_STEM = this.state.steps.isEmojiSelectionComplete
      ? "stem"
      : "invisible";
    if (this.state.fixStem) {
      CLASS_STEM += " fixed";
    }
    if (this.state.fixStemTop) {
      CLASS_STEM += " fixedTop";
    }

    // RENDER ELEMENTS
    const questionBubbles = this.renderQuestionBubbles();
    const downArrow = this.renderDownArrow();
    const upArrow = this.renderUpArrow();

    return (
      <>
        {upArrow}
        {downArrow}

        <p className={CLASS_TEXT_DOWN_ARROW} onClick={this.moveDown}>
          {this.state.textDownArrow}
        </p>

        <div className={CLASS_STEM} />

        <div className={CLASS_ERROR}>
          <p>
            There was a problem sending your results to Springpod. Please try
            again.
            {this.state.error.errorCode && (
              <>
                {" "}
                <span>(Error {this.state.error.errorCode})</span>
              </>
            )}
          </p>
        </div>

        <Progress parentState={this.state} totalQuestions={questions.length} />

        <Draggable
          position={this.state.emojiPosition}
          disabled={this.state.emojiDraggableDisabled}
          onStart={this.handleEmojiDragStart}
          onDrag={this.handlEmojiDrag}
          onStop={this.handleEmojiDragStop}
        >
          <div className={CLASS_DRAGGABLE}>
            <div className={CLASS_EMOJI}>
              <div className="emojiBack">
                <img
                  src={`/public/emoji/emoji-back-${this.state.emoji.type}-${
                    this.state.emoji.hair
                  }.svg`}
                  alt=""
                  draggable="false"
                />
              </div>
              <div className="emojiSkin">
                <img
                  src={`/public/emoji/emoji-skin-${this.state.emoji.skin}.svg`}
                  alt=""
                  draggable="false"
                />
              </div>
              <div className="emojiFront">
                <img
                  src={`/public/emoji/emoji-front-${this.state.emoji.type}-${
                    this.state.emoji.hair
                  }.svg`}
                  alt=""
                  draggable="false"
                />
              </div>
            </div>
          </div>
        </Draggable>

        <Intro
          parentState={this.state}
          setEmoji={this.setEmoji}
          setEmojiPosition={this.setEmojiPosition}
          emoji={this.state.emoji}
          isEmojiSelectionComplete={this.state.steps.isEmojiSelectionComplete}
          isIcebreakerStarted={this.state.steps.isIcebreakerStarted}
          compatibilityMode={this.compatibilityMode}
        />

        <Icebreaker
          parentState={this.state}
          parentSetState={this.parentSetState}
          compatibilityMode={this.compatibilityMode}
          setStep={this.setStep}
          setEmojiPosition={this.setEmojiPosition}
          disableScroll={this.disableScroll}
        />

        {questionBubbles}

        <Outro
          parentState={this.state}
          parentSetState={this.parentSetState}
          compatibilityMode={this.compatibilityMode}
          setStep={this.setStep}
          sendAnswers={this.sendAnswers}
          totalQuestions={questions.length}
          snapEmojiToAnswer={this.snapEmojiToAnswer}
        />
      </>
    );
  }
}

export default Quiz;
