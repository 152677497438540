import React, { Component } from 'react';

import './Progress.scss';

class Progress extends Component {
  render() {
    let height = 0;

    if (this.props.parentState.currentQuestion !== null) {
      height = ((this.props.parentState.currentQuestion + 1) / this.props.totalQuestions) * 100;
    }

    // Display while questions are being answered,
    // but hide before first question and hide during outro at end
    // TODO: Simplify this logic
    let className = '';

    if (!this.props.parentState.steps.isQuizComplete) {
      if (this.props.parentState.currentQuestion === null) {
        className = 'progress invisible';
      } else {
        className = 'progress visible';
      }
    } else {
      className = 'progress invisible';
    }

    // Create segments
    const SEGMENTS = [];

    for (let i = 0; i < this.props.totalQuestions; i++) {
      SEGMENTS.push(<div className="segment" key={i} style={{height: `calc(${100.0 / this.props.totalQuestions}% - 2px)`}}/>)
    }

    return (
      <div className={className}>
        <div className="progressInside" style={{height: `${height}%`}}></div>
        <div className="segments">
          {SEGMENTS}
        </div>
      </div>
    );
  }
}

export default Progress;
