import React, { Component } from 'react';

import EmojiSelector from '../EmojiSelector/EmojiSelector';

import './Intro.scss';

class Intro extends Component {
  render() {
    const CLASS_MAIN = this.props.isIcebreakerStarted ? 'hide' : 'intro';
    const CLASS_EMOJI_DISMISSED_TEXT = this.props.isEmojiSelectionComplete ? 'emojiDismissedText visibleDelay' : 'emojiDismissedText';
    const CLASS_EMOJI_EDITOR = this.props.isEmojiSelectionComplete ? 'invisible' : 'visible';

    const ELEMENT_NAME = this.props.parentState.springpod.user_foreName ? <span> {this.props.parentState.springpod.user_foreName}!</span> : '!';

    return (
      <section className={CLASS_MAIN}>
        <div className={CLASS_EMOJI_DISMISSED_TEXT}>
          <div>
            <p className="bigText">Let's <span className="redLighter">get</span> <span>started!</span></p>
            <p>First, a quick warm-up. {this.props.compatibilityMode ? 'Click next' : 'Scroll down'} to guide your character to it.</p>
          </div>
        </div>

        <div id="emojiSelectorContainer" className="centerContainer">
          <div className={CLASS_EMOJI_EDITOR}>
            <p className="bigText">Hi{ELEMENT_NAME}</p>
            <p>Let's create your character</p>
            <EmojiSelector
              setEmoji={this.props.setEmoji}
              setEmojiPosition={this.props.setEmojiPosition}
              emoji={this.props.emoji}
            />
          </div>
        </div>
      </section>
    );
  }
}

export default Intro;
