const questions = [
  {
    question: 'I like to work',
    answers: [
      {
        answer: 'On my own',
        iconUrl: '/public/img/icon-answer-0-0.svg',
        addTo: [
          'explorer', 'qualityController',
        ],
      },
      {
        answer: 'Somewhere in between',
        addTo: [
          'developer', 'innovator', 'campaigner', 'influencer',
        ],
      },
      {
        answer: 'With others',
        iconUrl: '/public/img/icon-answer-0-2.svg',
        addTo: [
          'investigator', 'coordinator', 'communicator', 'trainer', 'coach', 'manager',
        ],
      },
    ],
  },
  {
    question: 'I am good at',
    answers: [
      {
        answer: 'Being creative and thinking of ideas',
        iconUrl: '/public/img/icon-answer-1-0.svg',
        addTo: [
          'developer', 'innovator', 'campaigner', 'coach', 'manager', 'influencer',
        ],
      },
      {
        answer: 'Somewhere in between',
        addTo: [
          'investigator', 'coordinator', 'communicator', 'trainer',
        ],
      },
      {
        answer: 'Being practical and like to get stuck in',
        iconUrl: '/public/img/icon-answer-1-2.svg',
        addTo: [
          'explorer', 'qualityController',
        ],
      },
    ],
  },
  {
    question: 'I am good at',
    answers: [
      {
        answer: 'Reading and following instructions',
        iconUrl: '/public/img/icon-answer-2-0.svg',
        addTo: [
          'innovator', 'communicator', 'trainer', 'campaigner',
        ],
      },
      {
        answer: 'Somewhere in between',
        addTo: [
          'developer', 'coordinator', 'coach', 'manager',
        ],
      },
      {
        answer: 'Using my own initiative and work independently',
        iconUrl: '/public/img/icon-answer-2-2.svg',
        addTo: [
          'explorer', 'investigator', 'qualityController', 'influencer',
        ],
        definition: 'Someone who can take charge, lead or work something out for themselves',
      },
    ],
  },
  {
    question: 'I like to be',
    answers: [
      {
        answer: 'Organised / to plan',
        iconUrl: '/public/img/icon-answer-3-0.svg',
        addTo: [
          'coordinator', 'qualityController', 'trainer', 'coach', 'manager', 'influencer',
        ],
      },
      {
        answer: 'Somewhere in between',
        addTo: [
          'explorer', 'investigator', 'communicator', 'campaigner',
        ],
      },
      {
        answer: 'Flexible and see how things go',
        iconUrl: '/public/img/icon-answer-3-2.svg',
        addTo: [
          'developer', 'innovator',
        ],
      },
    ],
  },
  {
    question: 'I like',
    answers: [
      {
        answer: 'To see the overall idea and where I will end up',
        iconUrl: '/public/img/icon-answer-4-0.svg',
        addTo: [
          'innovator', 'campaigner', 'manager',
        ],
      },
      {
        answer: 'Somewhere in between',
        addTo: [
          'explorer', 'coordinator', 'communicator', 'trainer',
        ]
      },
      {
        answer: 'Lots of detail and want to see every part of the journey',
        iconUrl: '/public/img/icon-answer-0-2.svg',
        addTo: [
          'investigator', 'developer', 'qualityController', 'coach', 'influencer',
        ],
      },
    ],
  },
  {
    question: 'I enjoy',
    answers: [
      {
        answer: 'Finding ways to do things better',
        iconUrl: '/public/img/icon-answer-5-0.svg',
        addTo: [
          'developer', 'coordinator', 'qualityController', 'communicator',
          'coach', 'manager', 'influencer',
        ],
      },
      {
        answer: 'Somewhere in between',
        addTo: [
          'investigator', 'innovator', 'trainer',
        ],
      },
      {
        answer: 'Just getting on with it and going with the flow',
        iconUrl: '/public/img/icon-answer-5-2.svg',
        addTo: [
          'explorer', 'campaigner',
        ],
      },
    ],
  },
  {
    question: 'I like to find',
    answers: [
      {
        answer: 'Solutions to problems and solving puzzles',
        iconUrl: '/public/img/icon-answer-6-0.svg',
        addTo: [
          'explorer', 'investigator', 'developer', 'qualityController',
          'manager',
        ],
      },
      {
        answer: 'Somewhere in between',
        addTo: [
          'coordinator', 'communicator', 'trainer', 'campaigner',
        ],
      },
      {
        answer: 'Answers creatively and by thinking outside the box',
        iconUrl: '/public/img/icon-answer-6-2.svg',
        addTo: [
          'innovator', 'coach', 'influencer',
        ],
      },
    ],
  },
  {
    question: 'I am good at',
    answers: [
      {
        answer: 'Explaining (and simplifying) things',
        iconUrl: '/public/img/icon-answer-7-0.svg',
        addTo: [
          'developer', 'innovator', 'communicator', 'trainer', 'coach',
        ],
      },
      {
        answer: 'Somewhere in between',
        addTo: [
          'coordinator', 'campaigner', 'manager', 'influencer',
        ],
      },
      {
        answer: 'Finding out things',
        iconUrl: '/public/img/icon-answer-7-2.svg',
        addTo: [
          'explorer', 'investigator', 'qualityController',
        ],
      },
    ],
  },
  {
    question: 'I am good with',
    answers: [
      {
        answer: 'Words',
        iconUrl: '/public/img/icon-answer-8-0.svg',
        addTo: [
          'coordinator', 'communicator', 'trainer', 'campaigner',
        ],
      },
      {
        answer: 'Somewhere in between',
        addTo: [
          'explorer', 'developer', 'qualityController', 'coach', 'influencer',
        ],
      },
      {
        answer: 'Numbers and money',
        iconUrl: '/public/img/icon-answer-8-2.svg',
        addTo: [
          'investigator', 'innovator', 'manager',
        ],
      },
    ],
  },
  {
    question: 'I like',
    answers: [
      {
        answer: 'Understanding what people want and need',
        iconUrl: '/public/img/icon-answer-9-0.svg',
        addTo: [
          'explorer', 'investigator', 'developer', 'coordinator',
          'communicator', 'trainer',
        ],
      },
      {
        answer: 'Somewhere in between',
        addTo: [
          'innovator', 'campaigner', 'coach', 'manager', 'influencer',
        ],
      },
      {
        answer: 'Getting people to do what is needed',
        iconUrl: '/public/img/icon-answer-9-2.svg',
        addTo: [
          'qualityController',
        ],
      },
    ],
  },
  {
    question: 'I like to',
    answers: [
      {
        answer: 'Lead the team',
        iconUrl: '/public/img/icon-answer-10-0.svg',
        addTo: [
          'innovator', 'manager',
        ],
      },
      {
        answer: 'Somewhere in between',
        addTo: [
          'explorer', 'investigator', 'qualityController', 'communicator',
          'campaigner',
        ],
      },
      {
        answer: 'Help the team succeed',
        iconUrl: '/public/img/icon-answer-10-2.svg',
        addTo: [
          'developer', 'coordinator', 'trainer', 'coach', 'influencer',
        ],
      },
    ],
  },
  {
    question: 'I am',
    answers: [
      {
        answer: 'Diplomatic',
        iconUrl: '/public/img/icon-answer-11-0.svg',
        addTo: [
          'coordinator', 'qualityController', 'communicator', 'trainer',
          'coach', 'influencer',
        ],
        definition: 'Diplomatic means you can see both sides of a situation and are polite in sharing your ideas',
      },
      {
        answer: 'Somewhere in between',
        addTo: [
          'explorer', 'investigator', 'manager',
        ],
      },
      {
        answer: 'A disruptor (in a good way)',
        iconUrl: '/public/img/icon-answer-11-2.svg',
        addTo: [
          'developer', 'innovator', 'campaigner',
        ],
        definition: 'A disruptor is someone who likes to question and challenge ideas',
      },
    ],
  },
  {
    question: 'I like',
    answers: [
      {
        answer: 'Things to be done the right way',
        iconUrl: '/public/img/icon-answer-12-0.svg',
        addTo: [
          'coordinator', 'qualityController', 'trainer', 'coach', 'manager',
          'influencer',
        ],
      },
      {
        answer: 'Somewhere in between',
        addTo: [
          'investigator', 'developer', 'communicator',
        ],
      },
      {
        answer: 'To just get things done',
        iconUrl: '/public/img/icon-answer-12-2.svg',
        addTo: [
          'explorer', 'innovator', 'campaigner',
        ],
      },
    ],
  },
  {
    question: 'I like to',
    answers: [
      {
        answer: 'Jump in with new things',
        iconUrl: '/public/img/icon-answer-13-0.svg',
        addTo: [
          'explorer', 'investigator', 'developer', 'innovator', 'manager',
        ],
      },
      {
        answer: 'Somewhere in between',
        addTo: [
          'coordinator', 'communicator', 'campaigner', 'influencer',
        ],
      },
      {
        answer: 'Understand first, then get started',
        iconUrl: '/public/img/icon-answer-13-2.svg',
        addTo: [
          'qualityController', 'trainer', 'coach',
        ],
      },
    ],
  },
  {
    question: 'I am someone who',
    answers: [
      {
        answer: 'Loves big groups',
        iconUrl: '/public/img/icon-answer-14-0.svg',
        addTo: [
          'communicator', 'trainer', 'campaigner', 'coach', 'influencer',
        ],
      },
      {
        answer: 'Somewhere in between',
        addTo: [
          'coordinator', 'innovator', 'manager',
        ],
      },
      {
        answer: 'Prefers small groups or one to one',
        iconUrl: '/public/img/icon-answer-14-2.svg',
        addTo: [
          'explorer', 'investigator', 'developer', 'qualityController',
        ],
      },
    ],
  },
];

module.exports = questions;
