import React, { Component } from 'react';
import { Controller, Scene } from 'react-scrollmagic';
// eslint-disable-next-line
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import { Tween } from 'react-gsap';
import IsScrolling from '../libs/react-is-scrolling';
import scale from '../libs/scale';

class Question extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allowEnterDown: true,
      allowExitDown: true,
      allowEnterUp: false,
      allowExitUp: false,
    };

    this.renderAnswersContainer = this.renderAnswersContainer.bind(this);
    this.getScrollDirection = this.getScrollDirection.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  renderAnswersContainer() {
    let answers = this.props.parentState.answers;

    const answer0 = answers[`q${this.props.index}`] === 0 ? 'answer selected' : 'answer';
    const answer1 = answers[`q${this.props.index}`] === 1 ? 'answer selected' : 'answer';
    const answer2 = answers[`q${this.props.index}`] === 2 ? 'answer selected' : 'answer';

    return (
      <div className="answers">
        <div className={answer0} onClick={() => this.props.clickAnswer(0)}>
          <div className="icon">
            <img src={this.props.question.answers[0].iconUrl} alt="" />
          </div>
          <p>
            {this.props.question.answers[0].answer}
            {(this.props.question.answers[0].definition) && (
              <><br /><span className="iconHelper"></span></>
            )}
          </p>
        </div>

        <div className={answer1} onClick={() => this.props.clickAnswer(1)}>
          <div className="icon" />
          <p>
            {this.props.question.answers[1].answer}
            {(this.props.question.answers[1].definition) && (
              <><br /><span className="iconHelper"></span></>
            )}
          </p>
        </div>

        <div className={answer2} onClick={() => this.props.clickAnswer(2)}>
          <div className="icon">
            <img src={this.props.question.answers[2].iconUrl} alt="" />
          </div>
          <p>
            {this.props.question.answers[2].answer}
            {(this.props.question.answers[2].definition) && (
              <><br /><span className="iconHelper"></span></>
            )}
          </p>
        </div>
      </div>
    );
  }

  getScrollDirection() {
    let direction = null;

    if (this.props.isScrollingDown) {
      direction = 'down';
    } else if (this.props.isScrollingUp) {
      direction = 'up';
    }

    return direction;
  }

  handleScroll(progress) {
    // Ignore any events if icebreaker hasn't finished
    if (!this.props.parentState.steps.isIcebreakerComplete) {
      return;
    }

    // Ignore any events if question isn't active
    if (this.props.index !== 0 && this.props.parentState.answers[`q${this.props.index - 1}`] === null) {
      return;
    }

    if (this.props.compatibilityMode) {
      return;
    }

    const direction = this.getScrollDirection();

    // Only allow scrolling when above answer
    if (this.props.parentState.currentQuestion === this.props.index) {
      if (progress < 0.45 || progress > 0.55) {
        if (this.props.parentState.emojiDraggableDisabled === false) {
          this.props.parentSetState({
            emojiDraggableDisabled: true,
          });
        }
      } else {
        if (this.props.parentState.emojiDraggableDisabled) {
          this.props.parentSetState({
            emojiDraggableDisabled: false,
          });
        }
      }
    }

    // When entering question, scrolling down
    if (progress > 0.3 && this.state.allowEnterDown && direction === 'down') {
      this.setState({
        allowEnterDown: false,
        allowExitDown: true,
        allowExitUp: true,
      });

      if (this.props.parentState.isFreeScrolling && this.props.parentState.answers[`q${this.props.index}`] === null) {
        this.props.parentSetState({
          isFreeScrolling: false,
        });
      }

      this.props.setCurrentQuestion(this.props.index);

      this.props.parentSetState({
        isTextDownArrowVisible: false,
        buttonsBottomFade: true,
      });

      this.props.setEmojiPosition((window.innerWidth / 2) - (this.props.parentState.settings.EMOJI_WIDTH / 2), (window.innerHeight / 2) - this.props.parentState.settings.DISTANCE_FROM_SLIDER);

      this.props.disableScroll(true);

      scroller.scrollTo(`question-${this.props.index}`, {
        containerId: 'root',
        duration: 500,
        smooth: true,
        ignoreCancelEvents: true,
      });

      if (this.props.parentState.answers[`q${this.props.index}`] !== null) {
        this.props.parentSetState({
          returnToQuestion: true,
        });
      }
    }

    // Remove icebreaker when snapped to first question
    if (progress >= 0.5 && this.props.index === 0 && !this.state.allowEnterDown && !this.props.parentState.steps.isQuizStarted) {
      this.props.setStep('isQuizStarted', true);
    }

    // When leaving question, scrolling down
    if (progress > 0.7 && this.state.allowExitDown && direction === 'down') {
      this.setState({
        allowExitDown: false,
        allowEnterUp: true,
        allowExitUp: true,
      });

      this.props.parentSetState({
        isEmojiSelected: false,
      });

      this.props.resetEmojiPosition();
    }

    // When entering question, scrolling up
    if (progress < 0.7 && this.state.allowEnterUp && direction === 'up') {
      this.setState({
        allowEnterUp: false,
        allowExitUp: true,
        allowExitDown: true,
      });

      this.props.setCurrentQuestion(this.props.index);

      this.props.parentSetState({
        returnToQuestion: true,
      });

      this.props.setEmojiPosition((window.innerWidth / 2) - (this.props.parentState.settings.EMOJI_WIDTH / 2), (window.innerHeight / 2) - 100);

      scroller.scrollTo(`question-${this.props.index}`, {
        containerId: 'root',
        duration: 250,
        smooth: true,
        ignoreCancelEvents: true,
      });
    }

    // When leaving question, scrolling up
    if (progress < 0.3 && this.state.allowExitUp && direction === 'up') {
      this.setState({
        allowEnterDown: true,
        allowExitUp: false,
      });

      this.props.resetEmojiPosition();
    }
  }

  render() {
    let CLASS_NAME = `question-${this.props.index}`;

    if (this.props.index === 0) {
      if (!this.props.parentState.steps.isIcebreakerComplete) {
        CLASS_NAME += ' hide';
      }
    } else {
      if (this.props.parentState.answers[`q${this.props.index - 1}`] === null) {
        CLASS_NAME += ' hide';
      }
    }

    // Get current answers state
    const answers = this.props.parentState.answers;

    // Render answers for this question
    const answersContainer = this.renderAnswersContainer();

    // Get class name for dot: alternate between red and blue,
    // and modify if an answer has been selected
    let className = this.props.index % 2 === 0 ? 'dot blue' : 'dot red';
    if (answers[`q${this.props.index}`] !== null) {
      className += ' selected';
    }

    // Hide helper if an answer has been selected
    const CLASS_HELPER = answers[`q${this.props.index}`] !== null ? 'invisible helper' : 'visible helper';

    let CLASS_DEFINITION = 'invisible definition';
    let DEFINITION = null;

    if (answers[`q${this.props.index}`] !== null && this.props.question.answers[answers[`q${this.props.index}`]].definition !== null) {
      CLASS_DEFINITION = 'visible definition';
      DEFINITION = <p>{this.props.question.answers[answers[`q${this.props.index}`]].definition}</p>;
    }

    return (
      <section className={CLASS_NAME} name={`question-${this.props.index}`}>
        <div id={`question-${this.props.index}-trigger`} />

        <Controller container="#root">
          <Scene
            triggerElement={`#question-${this.props.index}-trigger`}
            duration={window.innerHeight}
            pin={false}>
            {(progress) => {
              this.handleScroll(progress);

              // CONFIG
              const BUBBLE_OVERFLOW = 100;

              // Calculate bubble target position
              const TARGET_WIDTH = this.props.parentState.viewport === 'tablet' ? this.props.parentState.settings.DOT_WIDTH : window.innerWidth + BUBBLE_OVERFLOW;
              const TARGET_HEIGHT = this.props.parentState.viewport === 'tablet' ? this.props.parentState.settings.DOT_HEIGHT : window.innerWidth + BUBBLE_OVERFLOW;

              let originWidth = 50;
              let originHeight = 50;
              let originLeft = 'calc(50% - (50px / 2))';
              let originTop = 'calc(50% - (50px / 2))';
              let targetLeft = `calc(50% - ${TARGET_WIDTH / 2}px)`;
              let targetTop = `calc(50% - ${TARGET_HEIGHT / 2}px)`;

              // Compatibility with MS Edge
              if (this.props.parentState.browserEngine === 'EdgeHTML') {
                originLeft = `${(window.innerWidth / 2) - 25}px`;
                originTop = `${(window.innerHeight / 2) - 25}px`;
                targetLeft = `${(window.innerWidth / 2) - (TARGET_WIDTH / 2)}px`;
                targetTop = `${(window.innerHeight / 2) - (TARGET_HEIGHT / 2)}px`;
              }

              // Compatibility with IE11
              if (this.props.parentState.browserEngine === 'Trident') {
                targetLeft = `${(window.innerWidth / 2) - (TARGET_WIDTH / 2)}px`;
                targetTop = `${(window.innerHeight / 2) - (TARGET_HEIGHT / 2)}px`;
                originLeft = targetLeft;
                originTop = targetTop;
                originWidth = TARGET_WIDTH;
                originHeight = TARGET_HEIGHT;
              }

              // Calculate dynamic opacity
              let OPACITY = scale(progress, 0.0, 0.5, 0.0, 1.0);

              if (progress > 0.5) {
                OPACITY = 1 - (OPACITY - 1);
              }

              return (
                <Tween
                  from={{
                    css: {
                      width: originWidth,
                      height: originHeight,
                      left: originLeft,
                      top: originTop,
                    },
                  }}
                  to={{
                    css: {
                      width: TARGET_WIDTH,
                      height: TARGET_HEIGHT,
                      left: targetLeft,
                      top: targetTop,
                    },
                  }}
                  totalProgress={progress}
                  yoyo={true}
                  repeat={1}
                  paused>
                  <div className={className}>
                    <div style={{opacity: OPACITY}}>
                      <div className="question">
                        <p className="number">{this.props.index + 1} of {this.props.totalQuestions}</p>
                        <p className="text">{this.props.question.question}</p>
                      </div>
                      <div className="sliderTrack" style={{left: 'calc(50% - (50vw - 40px))'}}/>
                      {answersContainer}
                      <div className={CLASS_HELPER}>
                        {(this.props.compatibilityMode) && (
                          <p>Click on your answer</p>
                        )}
                        {(!this.props.compatibilityMode) && (
                          <p>Drag your character onto your answer</p>
                        )}
                      </div>

                      <div className={CLASS_DEFINITION}>
                        {DEFINITION}
                      </div>
                    </div>
                  </div>
                </Tween>
              );
            }}
          </Scene>
        </Controller>
      </section>
    );
  }
}

export default IsScrolling(Question);
