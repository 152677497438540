const questions = require('./Questions.js');

const calculateResults = (answers) => {
  let results = {
    explorer: 0,
    investigator: 0,
    developer: 0,
    coordinator: 0,
    qualityController: 0,
    innovator: 0,
    communicator: 0,
    trainer: 0,
    campaigner: 0,
    coach: 0,
    manager: 0,
    influencer: 0,
  };

  for (let key in answers) {
    const QUESTION_NUM = parseInt(key.replace('q', ''));
    const ADD_TO = questions[QUESTION_NUM].answers[answers[key]].addTo;

    ADD_TO.forEach((type) => results[type] = results[type] + 1);
  }

  return results;
};

module.exports = calculateResults;
