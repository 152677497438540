import React, { Component } from 'react';

import './Fireworks.scss';

class Fireworks extends Component {
  render() {
    const CLASS_NAME = this.props.showElements ? 'fireworks show' : 'fireworks';

    return (
      <div className={CLASS_NAME}>
        <img className="fireworksStarMid" src="/public/fireworks-star-mid.svg" alt="" />
        <img className="fireworksStarLeft" src="/public/fireworks-star-left.svg" alt="" />
        <img className="fireworksStarRight" src="/public/fireworks-star-right.svg" alt="" />
        <img className="fireworksSparkles" src="/public/fireworks-sparkles.svg" alt="" />
        <img className="fireworksStreakTop" src="/public/fireworks-streak-top.svg" alt="" />
      </div>
    );
  }
}

export default Fireworks;
