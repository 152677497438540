import React, { Component } from 'react';
import 'babel-polyfill';
import { Switch, Route } from 'react-router-dom';
import 'mdn-polyfills/Array.prototype.forEach';
import ReactGA from 'react-ga';

import Quiz from './Quiz/Quiz';

import './sass/reset.css';
import './sass/typefaces.scss';
import './sass/animation.scss';
import './App.scss';

// CONFIG
const SPRINGPOD_URL = 'https://myskillsmylife.org.uk';

ReactGA.initialize('UA-158177045-1', { debug: false });

class App extends Component {
  render() {
    return (
      <Switch>
        <Route path='/quiz' component={Quiz} />
        <Route
          render={() => {
            window.location.href = SPRINGPOD_URL;
            return null;
          }}
        />
      </Switch>
    );
  }
}

export default App;
