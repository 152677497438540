import React, { Component } from 'react';
import { Controller, Scene } from 'react-scrollmagic';
// eslint-disable-next-line
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import { Tween } from 'react-gsap';
import { ReactPainter } from 'react-painter';
import ReactGA from "react-ga";

import './Icebreaker.scss';

class Icebreaker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      textDownHidden: false,
      stepCount: 0,
      buttonStepCount: -1,
      disableButton: true,
      showButton: false,
      allowEnter: true,
      hideInstructionText: false,
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.hideInstructionText = this.hideInstructionText.bind(this);
    this.handleDrawEnd = this.handleDrawEnd.bind(this);
    this.recalculate = this.recalculate.bind(this);

    this.isIcebreakerStarted = false;
  }

  componentDidMount() {
    this.recalculate();
    window.addEventListener('resize', this.recalculate);
  }

  recalculate() {
    // Get window width and height for calculations
    this.WINDOW_WIDTH = window.innerWidth;
    this.WINDOW_HEIGHT = window.innerHeight;

    if (this.props.parentState.steps.isEmojiSelectionComplete && this.props.parentState.steps.isIcebreakerStarted && !this.props.parentState.steps.isIcebreakerComplete) {
      const ICEBREAKER_DOT = document.querySelector('.icebreakerDot');

      this.props.setEmojiPosition(
        (window.innerWidth / 2) - (this.props.parentState.settings.EMOJI_WIDTH / 2),
        ICEBREAKER_DOT.getBoundingClientRect().top - (this.props.parentState.settings.EMOJI_HEIGHT / 2)
      );

      if (this.props.parentState.browserEngine === 'EdgeHTML' || this.props.parentState.browserEngine === 'Trident') {
        const WIDTH = parseInt(ICEBREAKER_DOT.style.getPropertyValue('width')
          .replace('px', ''));
        const HEIGHT = parseInt(ICEBREAKER_DOT.style.getPropertyValue('height')
          .replace('px', ''));

        ICEBREAKER_DOT.style.setProperty('left', `calc(50% - ${WIDTH /2}px)`);
        ICEBREAKER_DOT.style.setProperty('top', `calc(50% - ${HEIGHT / 2}px)`);
      }
    }

    if (this.props.parentState.steps.isEmojiSelectionComplete && this.props.parentState.steps.isIcebreakerStarted && this.props.parentState.steps.isIcebreakerComplete && !this.props.parentState.steps.isQuizStarted) {
      this.props.setEmojiPosition((window.innerWidth / 2) - (this.props.parentState.settings.EMOJI_WIDTH / 2), 15);
    }
  }

  handleScroll(progress) {
    if (progress > 0 && !this.state.textDownHidden) {
      this.setState({textDownHidden: true});
      this.props.parentSetState({isTextDownArrowVisible: false});
    }

    // Snap to icebreaker when scrolling down
    if (progress > 0.5 && this.state.allowEnter) {
      // HACK: Move trigger to prevent tween progress resetting to zero
      // when removing the intro
      document.getElementById('icebreakerTrigger').classList.add('offset');

      this.setState({
        allowEnter: false,
        showButton: true,
      });

      this.props.setEmojiPosition((window.innerWidth / 2) - (this.props.parentState.settings.EMOJI_WIDTH / 2), (window.innerHeight / 2) - (this.props.parentState.settings.ICEBREAKER_BUBBLE_SIZE / 2) - (this.props.parentState.settings.EMOJI_HEIGHT / 2));

      this.props.parentSetState({
        buttonsBottomShow: false,
        fixStem: true,
      });

      scroller.scrollTo('icebreaker', {
        containerId: 'root',
        duration: 500,
        smooth: true,
        ignoreCancelEvents: true,
      });
    }
  }

  skipToQuestions() {
    ReactGA.event({
      category: 'Icebreaker',
      action: this.isIcebreakerStarted ? "Started then skipped" : "Skipped",
    });

    this.props.setStep('isIcebreakerComplete', true);
    this.props.parentSetState({buttonsBottomShow: true});
    this.props.disableScroll(false);

    setTimeout(() => {
      scroller.scrollTo('question-0', {
        containerId: 'root',
        duration: 1000,
        smooth: this.props.compatibilityMode ? false : true,
        ignoreCancelEvents: true,
      });

      // Remove button
      const BUTTON_ICEBREAKER = document.getElementById('icebreakerButton');
      if (BUTTON_ICEBREAKER) {
        BUTTON_ICEBREAKER.parentNode.removeChild(BUTTON_ICEBREAKER);
      }

      if (this.props.compatibilityMode) {
        this.props.parentSetState({
          currentQuestion: 0,
          emojiPosition: {
            x: (window.innerWidth / 2) - (this.props.parentState.settings.EMOJI_WIDTH / 2),
            y: (window.innerHeight / 2) - this.props.parentState.settings.DISTANCE_FROM_SLIDER,
          },
        });
      }
    }, 50);
  }

  nextStep() {
    this.setState({
      stepCount: this.state.stepCount + 1,
      disableButton: true,
      hideInstructionText: false,
    });

    if (this.state.stepCount === 2) {
      this.props.disableScroll(false);
      this.setState({showButton: false});
      this.props.setEmojiPosition((window.innerWidth / 2) - (this.props.parentState.settings.EMOJI_WIDTH / 2), 15);

      this.props.parentSetState({
        buttonsBottomShow: true,
        isTextDownArrowVisible: true,
      });

      // Remove button
      const BUTTON_ICEBREAKER = document.getElementById('icebreakerButton');
      setTimeout(() => {
        BUTTON_ICEBREAKER.parentNode.removeChild(BUTTON_ICEBREAKER);
      }, 250);

      this.props.setStep('isIcebreakerComplete', true);

      ReactGA.event({
        category: 'Icebreaker',
        action: "Completed",
      });
    }
  }

  hideInstructionText() {
    this.isIcebreakerStarted = true;

    if (this.state.stepCount === 0) {
      this.setState({hideInstructionText: true});
    }
  }

  handleDrawEnd() {
    if (!this.state.disableButton) {
      return;
    }

    this.setState({
      hideInstructionText: true,
      disableButton: false,
      buttonStepCount: this.state.buttonStepCount + 1,
    });
  }

  render() {
    const CLASS_MAIN = this.props.parentState.steps.isQuizStarted ? 'invisible' : 'icebreaker';
    const CLASS_DOT = this.state.stepCount === 3 ? 'icebreakerDot dot shrink' : 'icebreakerDot dot';
    const CLASS_BUTTON = this.state.showButton ? 'button icebreakerButton' : 'button icebreakerButton invisible';
    const CLASS_SKIP_TO_QUESTION = this.state.stepCount < 3 ? 'secondaryButton' : 'secondaryButton invisible';
    const CLASS_ICEBREAKER_EXAMPLES = this.state.stepCount < 3 ? 'icebreakerExamples' : 'icebreakerExamples visible';
    const CLASS_OUTRO_TEXT = this.state.stepCount < 3 ? 'outroText' : 'outroText visible';
    const CLASS_CANVAS = this.props.parentState.steps.isIcebreakerStarted ? '' : 'noInteract';
    const CLASS_INSTRUCTION_TEXT = this.state.hideInstructionText ? 'invisible' : 'visible';

    const TEXT_INSTRUCTION = [
      'Draw a big square in this circle',
      'Now draw four smaller squares in your big square',
      'Finally, draw a triangle',
    ];

    const TEXT_BUTTON = [
      'I\'ve drawn a square',
      'I\'ve drawn the squares',
      'I\'ve drawn a triangle',
      'I\'ve drawn a triangle',
    ];

    // Calculate bubble target position
    const TARGET_WIDTH = this.props.parentState.settings.ICEBREAKER_BUBBLE_SIZE;
    const TARGET_HEIGHT = this.props.parentState.settings.ICEBREAKER_BUBBLE_SIZE;
    const TARGET_TOP = (this.WINDOW_HEIGHT / 2) - (this.props.parentState.settings.ICEBREAKER_BUBBLE_SIZE / 2);
    const INSTRUCTION_TOP = (this.props.parentState.settings.EMOJI_HEIGHT / 2) + 20;
    const BUTTON_TOP = (TARGET_HEIGHT && TARGET_TOP && this.props.parentState.settings.BUTTON_HEIGHT) ? TARGET_HEIGHT + TARGET_TOP - this.props.parentState.settings.BUTTON_HEIGHT + 2 : 0;

    let originWidth = 50;
    let originHeight = 50;
    let originLeft = 'calc(50% - 25px)';
    let originTop = 'calc(50% - 25px)';
    let targetLeft = `calc(50% - ${TARGET_WIDTH / 2}px)`;
    let targetTop = `calc(50% - ${TARGET_HEIGHT / 2}px)`;

    // Compatibility with MS Edge
    if (this.props.parentState.browserEngine === 'EdgeHTML') {
      originLeft = `${(this.WINDOW_WIDTH / 2) - 25}px`;
      originTop = `${(this.WINDOW_HEIGHT / 2) - 25}px`;
      targetLeft = `${(this.WINDOW_WIDTH / 2) - (TARGET_WIDTH / 2)}px`;
      targetTop = `${(this.WINDOW_HEIGHT / 2) - (TARGET_HEIGHT / 2)}px`;
    }

    // Compatibility with IE11
    if (this.props.parentState.browserEngine === 'Trident') {
      targetLeft = `${(window.innerWidth / 2) - (TARGET_WIDTH / 2)}px`;
      targetTop = `${(window.innerHeight / 2) - (TARGET_HEIGHT / 2)}px`;
      originLeft = targetLeft;
      originTop = targetTop;
      originWidth = TARGET_WIDTH;
      originHeight = TARGET_HEIGHT;
    }

    return (
      <section className={CLASS_MAIN} name="icebreaker">
        <div id="icebreakerTrigger" />
        <div className={CLASS_ICEBREAKER_EXAMPLES} />

        <Controller container="#root">
          <Scene
            triggerElement="#icebreakerTrigger"
            duration={this.WINDOW_HEIGHT / 2}
            pin={false}>
            {(progress) => {
              this.handleScroll(progress);

              return (
                <Tween
                  from={{
                    css: {
                      width: originWidth,
                      height: originHeight,
                      left: originLeft,
                      top: originTop,
                    },
                  }}
                  to={{
                    css: {
                      width: TARGET_WIDTH,
                      height: TARGET_HEIGHT,
                      left: targetLeft,
                      top: targetTop,
                    },
                  }}
                  totalProgress={progress}
                  paused>
                  <div className={CLASS_DOT}>
                    <div className="dot-contents">
                    <p className={CLASS_INSTRUCTION_TEXT} style={{top: INSTRUCTION_TOP}}>{TEXT_INSTRUCTION[this.state.stepCount]}</p>
                    <ReactPainter
                      width={500}
                      height={500}
                      initialColor="#FFF"
                      render={({ triggerSave, canvas }) => (
                        <div
                          onMouseDown={() => this.hideInstructionText()}
                          onTouchStart={() => this.hideInstructionText()}
                          onMouseUp={() => this.handleDrawEnd()}
                          onTouchEnd={() => this.handleDrawEnd()}
                          className={CLASS_CANVAS}
                        >
                          {canvas}
                        </div>
                      )}
                      />
                    </div>
                  </div>
                </Tween>
              );
            }}
          </Scene>
        </Controller>

        <button
          id="icebreakerButton"
          className={CLASS_BUTTON}
          disabled={this.state.disableButton}
          onClick={this.nextStep}
          style={{top: BUTTON_TOP}}
        >
          {TEXT_BUTTON[this.state.buttonStepCount]}
        </button>

        <button className={CLASS_SKIP_TO_QUESTION} onClick={() => this.skipToQuestions()}>Skip to questions</button>

        <div className={CLASS_OUTRO_TEXT}>
          <div className="top">
            <p>Here's some of the other ways people drew these shapes</p>
            <img src="/public/img/icon-icebreaker-arrow-left.svg" alt="" className="icebreakerArrowLeft" />
            <img src="/public/img/icon-icebreaker-arrow-right.svg" alt="" className="icebreakerArrowRight" />
          </div>
          <div className="bottom">
            <div>
              <p>There's no right or wrong way</p>
              <p className="bigText"><span>Everyone</span> <span className="redLighter">has</span> <span className="blueLighter">their</span> own way <span className="redLighter">of</span> <span>working</span></p>
              <p>Answer the next 15 questions to find out how<br /> you like to work and discover careers suited to you</p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Icebreaker;
